import { SET_ERROR, GET_SCAN_LIST, GET_SCAN_DETAILS } from "../constant/types";

const initialAuthState= {
 scanList:[],
 totalcount:'',
 scanDetail:{},
 error:{}
};

// Slice reducer tagReducer, gets only user object from whole state object
const ScanListReducer = (state=initialAuthState, action) => {
    switch (action.type) {
        case GET_SCAN_LIST:
            return{
                ...state,
                scanList: action.payload?.scanListData.scanList,
                totalcount:action.payload?.scanListData.totalcount,
                error: {}
            }
        case GET_SCAN_DETAILS:
            return{
                ...state,
                scanDetail: action.payload,
                error: {}
            }
        case SET_ERROR:
            return {
                ...state,
                items: [],
                errors: action.payload?.message,
            }
        default:
                return state;
            }
    }

export default ScanListReducer;
