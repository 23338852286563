import http from '../../services/HttpClient';
import LocalStorageService from '../../services/localStorage';
import {
  USER_AUTHENTICATION,
  SET_ERROR,
  UPDATE_USER,
  GET_USER,
  CHANGE_USER_STATUS,
  SEND_OTP,
  VERIFY_OTP,
  USER_LOGOUT,
  RESET_STATE,
} from '../constant/types';

const localStorageService = LocalStorageService.getService();

const UserAuthentication = (userArg) => async (dispatch) => {
  let user = null;
  try {
    user = await http.post('/api/nfc/v1/admin/authenticate', userArg);
    localStorageService.setToken(user?.data.response);
    dispatch({
      type: USER_AUTHENTICATION,
      payload: { user: user?.data.response },
    });
  } catch (error) {
    console.log('error');
    dispatch({
      type: SET_ERROR,
      payload: { message: `${error}` },
    });
  }
};

const UserRegistration = (userArg,cb) => async (dispatch) => {
  let user = null;
  try {
    user = await http.post('/api/nfc/v1/admin/register', userArg);
    localStorage.setItem('user', JSON.stringify(user?.data.response));
    dispatch({
      type: USER_AUTHENTICATION,
      payload: { user: user?.data.response.User },
    });
    alert(user.data.successMsg)
    cb()
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `${error}` },
    });
  }
};

const EditUser = (userArg,cb) => async (dispatch) => {
  let user = null;
  try {
    user = await http.post(`/api/nfc/v1/admin/updateUser`, userArg);
    dispatch({
      type: UPDATE_USER,
      payload: { user: user?.data.response.User },
    });
    alert(user.data.successMsg)
    cb()
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: { message: `${error}` },
    });
  }
};

const GetUser = (url, userArg) => async (dispatch) => {
  let user = null;
  try {
    user = await http.post(`/api/nfc/v1/${url}`, userArg);
    dispatch({
      type: GET_USER,
      payload: { user: user?.data.response.User },
    });
  } catch (error) {
    console.log('error');
    dispatch({
      type: SET_ERROR,
      payload: { message: `${error}` },
    });
  }
};

const ChangeStatus = (url, userArg) => async (dispatch) => {
  let user = null;
  try {
    user = await http.post(`/api/nfc/v1/${url}`, userArg);
    dispatch({
      type: CHANGE_USER_STATUS,
      payload: { user: user?.data.response.User },
    });
    alert(user.data.successMsg)
  } catch (error) {
    console.log('error');
    dispatch({
      type: SET_ERROR,
      payload: { message: `${error}` },
    });
  }
};

const sendOtpMail = (userArg) => async (dispatch) => {
  let otp = null;
  try {
    otp = await http.post('/api/nfc/v1/admin/changePassword', userArg);
    console.log(otp);
    dispatch({
      type: SEND_OTP,
      payload: { user: otp?.data.response.SaveResponse },
    });
  } catch (error) {
    console.log('error');
    dispatch({
      type: SET_ERROR,
      payload: { message: `${error}` },
    });
  }
};

const verifyMail = (userArg) => async (dispatch) => {
  let verification = null;
  try {
    verification = await http.post('/api/nfc/v1/admin/verifyMail', userArg);
    dispatch({
      type: VERIFY_OTP,
      payload: { user: verification?.data.response.data },
    });
  } catch (error) {
    console.log('error');
    dispatch({
      type: SET_ERROR,
      payload: { message: `${error}` },
    });
  }
};

const sendOtp = (userArg) => async (dispatch) => {
  let otp = null;
  try {
    otp = await http.post('/api/nfc/v1/admin/sendOtp', userArg);
    console.log(otp);
    dispatch({
      type: SEND_OTP,
      payload: { user: otp?.data.response.User },
    });
  } catch (error) {
    console.log('error',error);
    dispatch({
      type: SET_ERROR,
      payload: { message: `${error}` },
    });
  }
};

const verifyOtp = (userArg) => async (dispatch) => {
  let otp = null;
  try {
    otp = await http.post('/api/nfc/v1/admin/verifyOtp', userArg);
    console.log(otp);
    dispatch({
      type: SEND_OTP,
      payload: { user: otp?.data.response.User },
    });
  } catch (error) {
    console.log('error');
    dispatch({
      type: SET_ERROR,
      payload: { message: `${error}` },
    });
  }
};

const logout=()=>async(dispatch)=>{
  try {
    let nameId=localStorageService.getNameId()
    let sessionIndex=localStorageService.getSessionIndex()
    const data={
      sessionIndex,
      nameId
    }
    const logoutrequest=await http.post('/api/logout/sso',data);
    console.log(logoutrequest);
    if (logoutrequest.data.status==="OK") {
     let logoutWindow=window.open('https://www.edgeadmin.dupont/uploads/logout.html', '_blank')
      localStorageService.clearToken();
      setTimeout(() => {
        logoutWindow.close()
      }, 2000);
      
    }
    
  
    dispatch({
      type: USER_LOGOUT,
      payload: { user:false },
    });
  } catch (error) {
    console.log('error');
    dispatch({
      type: SET_ERROR,
      payload: { message: `${error}` },
    });
  }

}



const resetState=(flag)=>async(dispatch)=>{
if (flag==="adduser") {
  dispatch({
    type: RESET_STATE,
  });
}
  

}

export default {
  UserAuthentication,
  UserRegistration,
  EditUser,
  GetUser,
  ChangeStatus,
  sendOtp,
  verifyOtp,
  sendOtpMail,
  verifyMail,
  logout,
  resetState,
 
};
