import { GET_SETTINGS,SAVE_SETTINGS,SET_ERROR} from "../constant/types";

const initialState= {
    items: [],
    errors: {},
    notificationState:{}
  };
  
  const SettingReducer = (state=initialState, action) => {
    switch (action.type) {
      case GET_SETTINGS:
        return{
          ...state,
          items: action.payload?.setting,
          errors:{}
        }
      case SAVE_SETTINGS:
        return{
          ...state,
          items: action.payload?.setting,
          errors:{}
        }
        case SET_ERROR:
            return{
              ...state,
              items: [],
              errors:{message:action.payload.message}
            }
      default:
        return state;
    }
  }
  
  export default SettingReducer;
  