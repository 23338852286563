import Axios from 'axios';
// import LocalStorageService from './localStorage';
import Cookies from 'js-cookie';

// const localStorageService = LocalStorageService.getService();
// const baseUrl =
//   process.env.NODE_ENV === 'development'
//     ? 'http://localhost:5001'
//     : 'https://dupont.arachnomesh.com';
const baseUrl = 'https://edgeadmin.dupont';


const http = Axios.create({
  baseURL: baseUrl,
});

http.interceptors.request.use((requestConfig) => {
  try{
    const token = Cookies.get('token');
  if (requestConfig && requestConfig.headers) {
    requestConfig.headers.Authorization = `Bearer ${token}`;
  }
 
  }catch(err){
    console.log(err);
  }
 return requestConfig;

});

http.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    console.log('Error occurs at api call: ', err.response);
    throw new Error(err.response.data.errorMsg);

    // if (err.response.status === 401) {
    //   // Do some stuff
    // }
  },
);

export default http;
